<template lang="pug">
div.bg-gray
  div.pr-5.thirstlevel(style="top:100px")
    v-row.flex-center
      v-col.pr-0.pb-0
        bread-crumbs(:borderBottom="false" :paramCOD="$route.params.paramCOD")
  div
    div.gridFull
      v-row.headTitleGrid
        v-col.titleGrid(md="8")
          span #[translate Surveys]
        v-col(md="4")
      v-row
        v-col#tableRanking(md="12")
          div.headerTableRanking.row.ma-0
            div.headerItem.colName.d-flex.pl-3
              span #[translate Name]
              <svg class="ml-3" :class="{'rotate-180': sortParam==='center__name' && sortDesc}" @click="setOrdering('center__name')" xmlns="http://www.w3.org/2000/svg" width="8.121" height="8.819" viewBox="0 0 8.121 8.819">
                <g id="Grupo_9758" data-name="Grupo 9758" transform="translate(-329.439 -285.439)">
                  <g id="Grupo_8767" data-name="Grupo 8767" transform="translate(336.5 286.5) rotate(90)">
                    <g id="Grupo_8768" data-name="Grupo 8768">
                      <line id="Línea_652" data-name="Línea 652" x1="3" y2="3" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
                      <line id="Línea_760" data-name="Línea 760" x2="3" y2="3" transform="translate(0 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
                      <path id="Trazado_1354" data-name="Trazado 1354" d="M0,0H7" transform="translate(0.008 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
                    </g>
                  </g>
                </g>
              </svg>
            div.headerItem.colBrand
            div.headerItem.colUrl
              span #[translate Url]
            div.headerItem.colQr
              span #[translate Qr]
            div.headerItem.colQrTransparent
              span #[translate TQr]            
            div.headerItem.colQrTransparent
              span #[translate White Qr]            
          div.contentTableRanking.row.ma-0
            template(v-for="(item, index) in itemsRanking")
              .itemContent.col-12.pa-0
                div.item.colName
                  p.mb-0 {{ item.center.name }}
                div.item.colBrand
                  p.mb-0 {{ item.brand }}
                div.item.colUrl
                  p.mb-0
                    a.link-text(:href="item.url + (isStaff ? '?isStaff=true' : '')" target="_blank") {{ item.url }}
                  p.mb-0
                    a.link-text(:href="item.short_url + (isStaff ? '?isStaff=true' : '')" target="_blank") {{ item.short_url }}
                div.item.colQr
                  button(@click="showQr = true; qrActive = item")
                    img(:src="item.code_qr" class="miniQr")
                div.item.colQrTransparent
                  button(@click="showTQr = true; qrActive = item")
                    v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                      template(v-slot:activator="{ on, attrs }")
                        img(v-bind="attrs" v-on="on" :src="item.transparent_code_qr" class="miniQr")
                      span Qr fondo Transparente
                div.item.colQrTransparent
                  button(@click="showWhiteQr = true; qrActive = item")
                    v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                      template(v-slot:activator="{ on, attrs }")
                        img(v-bind="attrs" v-on="on" :src="item.transparent_code_qr" class="miniQr")
                      span Qr Blanco



            p.ml-3(v-if="!itemsRanking") #[translate No data]
      v-dialog.dialogEdit(v-model="showTQr" width="500")
        v-card.dialogEdit
          v-card-text.pb-0
          v-container
            v-row.justify-center(v-if="qrActive")
              img(:src="qrActive.transparent_code_qr" width="180")
            v-row.justify-center.text-12(v-if="qrActive")
              a(:href="qrActive.url + (isStaff ? '?isStaff=true' : '')" target="_blank") {{ qrActive.url }}
      v-dialog.dialogEdit(v-model="showQr" width="500")
        v-card.dialogEdit
          v-card-text.pb-0
          v-container
            v-row.justify-center(v-if="qrActive")
              img(:src="qrActive.code_qr" width="180")
            v-row.justify-center.text-12(v-if="qrActive")
              a(:href="qrActive.url + (isStaff ? '?isStaff=true' : '')" target="_blank") {{ qrActive.url }}
      v-dialog.dialogEdit(v-model="showWhiteQr" width="500")
        v-card.dialogEdit.dark-background
          v-card-text.pb-0
          v-container
            v-row.justify-center(v-if="qrActive")
              img(:src="qrActive.white_code_qr" width="180")
            v-row.justify-center.text-12(v-if="qrActive")
              a(:href="qrActive.white_code_qr + (isStaff ? '?isStaff=true' : '')" target="_blank") {{ qrActive.url }}

</template>

<script>
import SrvSatisfaction from '@/services/ranking-satisfaction.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'

export default {
  mixins: [commonMixins],
  data() {
    return {
      page: 1,
      itemsRanking: null,
      itemsPerPage: 25,
      nextScrollUrl: null,
      nextCall: false,
      showQr: false,
      showTQr: false,
      showWhiteQr: false,
      sortParam: 'center__name',
      sortDesc: false,
      qrActive: null
    }
  },
  components: { BreadCrumbs },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 3)
    this.getQrs();
  },
  mounted() {
    this.scroll()
  },
  methods: {
    setOrdering(field) {
      if (this.sortParam === field) {
        this.sortDesc = !this.sortDesc
      }
      this.sortParam = field
      this.getQrs();
    },
    // OBTENEMOS EL RANKING
    getQrs: async function (nextUrl = null) {
      if (!nextUrl) {
        let ordering = (this.sortDesc ? '-' : '') + this.sortParam
        const res = await SrvSatisfaction.getSatisfactionSurveyQR(ordering)
        if (res.status === 200) {
          this.itemsRanking = res.data.results
          this.nextScrollUrl = res.data.next
          this.$store.commit(types.SATISFACTION_RANKING, res.data)
          this.loading = false
        }
      } else {
        const res = await SrvSatisfaction.getSatisfactionRankingNext(nextUrl)
        if (res.status === 200) {
          res.data.results.forEach(element => {
            this.itemsRanking.push(element)
          });
          this.nextScrollUrl = res.data.next
          this.$store.commit(types.SATISFACTION_RANKING, res.data)
          this.nextCall = false
        }
      }
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'HealthyPokeSurveysHome') {
          var body = document.body, html = document.documentElement
          var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getQrs(this.nextScrollUrl)
          }
        }
      }
    }
  },
  watch: {
    filterApply: function () {
      this.getQrs()
    }
  },
}
</script>

<style lang="scss" scoped>
.dialogEdit {
  padding: 50px 0;

  &.dark-background {
    background: #222226
  }
}

.colName {
  max-width: 15%;
  flex: 0 0 15%;

  p {
    margin-left: 12px;
  }
}

.colBrand {
  max-width: 11%;
  flex: 0 0 11%;
}

.colUrl {
  max-width: 50%;
  flex: 0 0 50%;
}

.colQr,
.colQrTransparent {
  max-width: 8%;
  flex: 0 0 8%;
  display: flex;
  justify-content: center;

  .miniQr {
    border: 2px solid #3A84FF;
    width: 30px;
  }
}

#tableRanking {
  padding: 2px;

  .headerTableRanking,
  .itemContent {
    text-align: left;
    font-size: 0.875rem;
    border-top: 1px solid #9797973d;
    min-height: 41px;
    display: flex;
    align-items: center;
  }
}
</style>
